/**
 * Created by awadhesh on 11/11/19.
 */
import axios from "axios";

export function getSourceId() {
  return 2; // Static source ID
}

// Create an axios instance
const axiosInstance = axios.create({});

// Request Interceptor: Adds headers or modifies requests as needed
axiosInstance.interceptors.request.use(
  async (request) => {
    // Example: Attach sourceId and other custom headers
    const sourceId = getSourceId();
    request.headers["sourceId"] = sourceId;

    // Add Authorization header if available
    const token = localStorage.getItem("authorization");
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    // Handle request errors
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Response Interceptor: Handles responses and errors
axiosInstance.interceptors.response.use(
  (response) => {
    // Log response URL and data
    console.log(`Response from ${response?.request?.responseURL}:`, response);
    return response?.data || response;
  },
  (error) => {
    if (error.response) {
      console.error(
        `Error from ${error.response?.request?.responseURL}:`,
        error.response.data
      );
      return Promise.reject(error.response.data); // Return server error
    }

    console.error("Unknown error:", error);
    return Promise.reject({ message: "An unknown error occurred." });
  }
);

/**
 * Get Authorization (Utility function)
 * @param {*} body Request body (if needed)
 * @param {*} headers Custom headers (if needed)
 * @param {*} url Request URL (if needed)
 * @returns Authorization token or null
 */
export const getAuthorization = () => {
  const token = localStorage.getItem("authorization");
  return token ? `Bearer ${token}` : null;
};

// Export the configured axios instance
const Service = {
  getInstance: axiosInstance,
};

export default Service;
