import React from "react";
import DownloadApp from "../DownloadApp/DownloadApp";
import ProfileHeader from "../Header/ProfileHeader";
import PointerSection from "../PointerSection/PointerSection";
import ProfileFooter from "../ProfileSection/ProfileFooter";
import photo2 from "../assets/image/contactUs/photo-2.png";
import iconCall from "../assets/image/contactUs/call.svg";
import iconMsg from "../assets/image/contactUs/msg.svg";
import iconLocation from "../assets/image/contactUs/lctn.svg";
import "./ContactUs.css";
import ContactUsInput from "./ContactUsInput";
import TextArea from "./TextArea/TextArea";

function ContactUs() {
  return (
    <>
      <div className="full--container">
        <div className="main--section">
          {/* Contact Header Section */}
          <div className="contact--top">
            <div className="contact--left">
              <div className="contact--left-inner">
                <PointerSection title="Contact Us" colorText="text--white" />
                <h1 className="big--title">Feel Free to Reach Us</h1>
              </div>
            </div>
          </div>

          {/* Contact Info Section */}
          <div className="w-1211">
            <div className="get--in-touch">
              {/* Left Image */}
              <div className="get--in-image">
                <img src={photo2} alt="Contact Illustration" />
              </div>

              {/* Contact Details */}
              <div className="get--in-right">
                <PointerSection title="Let’s Talk" colorText="text--black" />
                <h2 className="get--tuch-title">Reach Out to Us Anytime</h2>
                <p className="get--tuch-info">
                  Whether you have a question, need assistance, or want to
                  explore how we can help you, we’re here to listen. Our team is
                  committed to providing the support you need, whenever you need
                  it. Don’t hesitate to get in touch—we’d love to hear from you!
                </p>

                {/* Contact Details */}
                <div className="get--in-details">
                  <ContactItem
                    icon={iconCall}
                    title="Have Questions? Let's Chat!"
                    info="Free +01 569 896 654 | Mon-Fri: 9 AM - 6 PM"
                  />
                  <ContactItem
                    icon={iconMsg}
                    title="Drop Us a Line"
                    info="Info@whitecollar.com | Response Within 24 Hours"
                  />
                  <ContactItem
                    icon={iconLocation}
                    title="Swing By Our Office"
                    info="King Street, Prior Lake, New York | Mon-Fri: 9 AM - 5 PM"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="w-1211">
            <div className="contact--form-section">
              <div className="contact--form-header">
                <PointerSection title="Contact Us" colorText="text--black" />
                <span className="contact--header-title">
                  Request A Call Back !
                </span>
              </div>
              <div className="contact--form-body">
                <div className="col--2 sm--col-1">
                  <ContactUsInput
                    typeInput="text"
                    inputClass="input--contact"
                    labelName="Your Name"
                  />
                </div>
                <div className="col--2 sm--col-1">
                  <ContactUsInput
                    typeInput="email"
                    inputClass="input--contact"
                    labelName="Email Address"
                  />
                </div>
                <div className="col--2 sm--col-1">
                  <ContactUsInput
                    typeInput="text"
                    inputClass="input--contact"
                    labelName="Phone Number"
                  />
                </div>
                <div className="col--2 sm--col-1">
                  <ContactUsInput
                    typeInput="text"
                    inputClass="input--contact"
                    labelName="Subject"
                  />
                </div>
                <div className="col-1">
                  <TextArea
                    inputClass="input--contact"
                    labelName="Message..."
                  />
                </div>
              </div>
              <div className="contact--form-footer">
                <button type="button" className="btn--contact-submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* Contact Item Component */
const ContactItem = ({ icon, title, info }) => (
  <div className="get--in-items">
    <div className="get-in-icon">
      <img src={icon} alt={`${title} Icon`} />
    </div>
    <div className="get--items-info">
      <h3 className="get--text">{title}</h3>
      <p className="get--sub-text">{info}</p>
    </div>
  </div>
);

export default ContactUs;
