import React, { useEffect, useState } from "react";
import ProfileHeader from "../../Header/ProfileHeader";
import { useLocation } from "react-router-dom";
import { routes } from "./routes";

const getUserCredential = () => {
  try {
    return JSON.parse(localStorage.getItem("userCredential"));
  } catch {
    return null; // Default to null if parsing fails
  }
};

const AuthenticatedLayout = ({ children }) => {
  const [userCredential, setUserCredential] = useState(null);
  const location = useLocation(); // Get the current route location.
  const currentRoute = routes.find((route) => route.path === location.pathname); // Find the matching route.

  const isHeaderShown = currentRoute?.isHeaderShown ?? false; // Default to false if no route matches.

  useEffect(() => {
    setUserCredential(getUserCredential());
  }, [location]);
  useEffect(() => {
    const handleStorageChange = () => {
      alert("localStorage changed!");
      setUserCredential(getUserCredential());
    };

    // Listen for localStorage changes across tabs
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <>
      {isHeaderShown && <ProfileHeader userCredential={userCredential} />}
      {/* {userCredential?.tokens && <ProfileHeader />} */}
      {children}
    </>
  );
};

export default AuthenticatedLayout;
