import React, { useState } from "react";
import QuizHistoryCard from "./QuizHistoryCard";
import didYouKnow from "../assets/image/didyouknow.svg";
import CustomeSelect from "../LearnPage/CustomeSelect/CustomeSelect";
import iconDashboard from "../assets/image/dashboard.svg";
import iconTopic from "../assets/image/topic-icon.svg";
import iconDifficulty from "../assets/image/difficulty-icon.svg";
import "./QuizHistory.css";
import Feedback from "../ModalComponent/Feedback/Feedback";
import { useEffect } from "react";
import { type } from "@testing-library/user-event/dist/type";

const quizDatatemp = {
  categories: [
    {
      id: 1,
      name: "Stock Market Basics",
      topics: [
        { topicId: 101, name: "Understanding Stocks" },
        { topicId: 102, name: "Stock Exchanges" },
        { topicId: 103, name: "How Stock Trading Works" },
        { topicId: 104, name: "Market Risks" },
      ],
    },
    {
      id: 2,
      name: "Technical Analysis",
      topics: [
        { topicId: 201, name: "Chart Patterns" },
        { topicId: 202, name: "Technical Indicators" },
        { topicId: 203, name: "Support & Resistance" },
        { topicId: 204, name: "Volume Analysis" },
      ],
    },
    {
      id: 3,
      name: "Futures and Options",
      topics: [
        { topicId: 301, name: "Futures Contracts" },
        { topicId: 302, name: "Options Strategies" },
        { topicId: 303, name: "Options Pricing Models" },
        { topicId: 304, name: "Risk Management" },
      ],
    },
    {
      id: 4,
      name: "Mutual Funds and ETFs",
      topics: [
        { topicId: 401, name: "Types of Mutual Funds" },
        { topicId: 402, name: "ETFs" },
        { topicId: 403, name: "Selecting Mutual Funds" },
        { topicId: 404, name: "Taxation of Mutual Funds" },
      ],
    },
    {
      id: 5,
      name: "Personal Finance",
      topics: [
        { topicId: 501, name: "Budgeting & Saving" },
        { topicId: 502, name: "Debt Management" },
        { topicId: 503, name: "Retirement Planning" },
        { topicId: 504, name: "Insurance" },
      ],
    },
  ],
  difficultyLevels: [
    { id: 1, name: "Easy" },
    { id: 2, name: "Medium" },
    { id: 3, name: "Hard" },
  ],
};
const quizHistory = [
  {
    quizId: 101,
    categoryId: 1,
    topicId: 101,
    difficultyId: 1,
    dateTaken: "2024-10-01",
    totalScore: 2,
    title: {
      category: "Stocks",
      topic: "Introduction to Stocks",
      level: "Easy",
    },

    rank: 1,
    questions: [
      {
        questionId: 1,
        questionText: "What is a stock?",
        options: [
          {
            optionId: 101,
            optionText: "A bond issued by a company",
            isCorrect: false,
          },
          {
            optionId: 102,
            optionText: "A share of ownership in a company",
            isCorrect: true,
          },
          { optionId: 103, optionText: "A debt instrument", isCorrect: false },
          {
            optionId: 104,
            optionText: "A type of commodity",
            isCorrect: false,
          },
        ],
        selectedOptionId: 102,
        correctOptionId: 102,
        points: 1,
      },
      {
        questionId: 2,
        questionText: "Which type of stock provides voting rights?",
        options: [
          { optionId: 201, optionText: "Preferred Stock", isCorrect: false },
          { optionId: 202, optionText: "Common Stock", isCorrect: true },
          { optionId: 203, optionText: "Dividend Stock", isCorrect: false },
          { optionId: 204, optionText: "Treasury Stock", isCorrect: false },
        ],
        selectedOptionId: 202,
        correctOptionId: 202,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 102,
    categoryId: 2,
    topicId: 201,
    difficultyId: 1,
    dateTaken: "2024-10-02",
    totalScore: 2,

    title: {
      category: "Technical Analysis",
      topic: "Chart Patterns",
      level: "Medium",
    },

    rank: 2,
    questions: [
      {
        questionId: 3,
        questionText: "What is a bond?",
        options: [
          {
            optionId: 301,
            optionText: "A share in a company",
            isCorrect: false,
          },
          {
            optionId: 302,
            optionText: "A loan made by an investor to a borrower",
            isCorrect: true,
          },
          { optionId: 303, optionText: "A type of stock", isCorrect: false },
          {
            optionId: 304,
            optionText: "A derivative instrument",
            isCorrect: false,
          },
        ],
        selectedOptionId: 302,
        correctOptionId: 302,
        points: 1,
      },
      {
        questionId: 4,
        questionText: "What is the main risk associated with bonds?",
        options: [
          { optionId: 401, optionText: "Market risk", isCorrect: false },
          { optionId: 402, optionText: "Default risk", isCorrect: true },
          { optionId: 403, optionText: "Liquidity risk", isCorrect: false },
          { optionId: 404, optionText: "Interest rate risk", isCorrect: false },
        ],
        selectedOptionId: 402,
        correctOptionId: 402,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 103,
    categoryId: 3,
    topicId: 301,
    difficultyId: 2,
    dateTaken: "2024-10-05",
    totalScore: 1,
    title: {
      category: "Investing",
      topic: "Types of Investments",
      level: "Easy",
    },
    rank: 3,
    questions: [
      {
        questionId: 5,
        questionText: "What is the purpose of diversification in investing?",
        options: [
          { optionId: 501, optionText: "To reduce risk", isCorrect: true },
          {
            optionId: 502,
            optionText: "To maximize returns",
            isCorrect: false,
          },
          {
            optionId: 503,
            optionText: "To increase volatility",
            isCorrect: false,
          },
          { optionId: 504, optionText: "To lower taxes", isCorrect: false },
        ],
        selectedOptionId: 501,
        correctOptionId: 501,
        points: 1,
      },
      {
        questionId: 6,
        questionText: "What is the best time to invest in stocks?",
        options: [
          {
            optionId: 601,
            optionText: "When prices are high",
            isCorrect: false,
          },
          { optionId: 602, optionText: "When prices are low", isCorrect: true },
          {
            optionId: 603,
            optionText: "Only during recessions",
            isCorrect: false,
          },
          {
            optionId: 604,
            optionText: "At the beginning of the year",
            isCorrect: false,
          },
        ],
        selectedOptionId: 602,
        correctOptionId: 602,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 104,
    categoryId: 4,
    topicId: 401,
    difficultyId: 2,
    dateTaken: "2024-10-10",
    totalScore: 1,
    title: {
      category: "Options Trading",
      topic: "Basics of Options",
      level: "Medium",
    },
    rank: 4,
    questions: [
      {
        questionId: 7,
        questionText: "What is an ETF?",
        options: [
          {
            optionId: 701,
            optionText: "Exchange-Traded Fund",
            isCorrect: true,
          },
          {
            optionId: 702,
            optionText: "Equity Transfer Fund",
            isCorrect: false,
          },
          {
            optionId: 703,
            optionText: "Electronic Trading Fund",
            isCorrect: false,
          },
          {
            optionId: 704,
            optionText: "Exchange-Traded Futures",
            isCorrect: false,
          },
        ],
        selectedOptionId: 701,
        correctOptionId: 701,
        points: 1,
      },
      {
        questionId: 8,
        questionText: "Which of the following is a benefit of ETFs?",
        options: [
          {
            optionId: 801,
            optionText: "High management fees",
            isCorrect: false,
          },
          { optionId: 802, optionText: "Tax efficiency", isCorrect: true },
          {
            optionId: 803,
            optionText: "Inflexibility in trading",
            isCorrect: false,
          },
          { optionId: 804, optionText: "Limited access", isCorrect: false },
        ],
        selectedOptionId: 802,
        correctOptionId: 802,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 105,
    categoryId: 5,
    topicId: 501,
    difficultyId: 1,
    dateTaken: "2024-10-18",
    totalScore: 2,

    title: {
      category: "Financial Analysis",
      topic: "Understanding Financial Statements",
      level: "Hard",
    },

    rank: 5,
    questions: [
      {
        questionId: 9,
        questionText: "What is the primary goal of portfolio diversification?",
        options: [
          { optionId: 901, optionText: "Maximize returns", isCorrect: false },
          { optionId: 902, optionText: "Reduce risk", isCorrect: true },
          {
            optionId: 903,
            optionText: "Increase volatility",
            isCorrect: false,
          },
          {
            optionId: 904,
            optionText: "Minimize capital gains tax",
            isCorrect: false,
          },
        ],
        selectedOptionId: 902,
        correctOptionId: 902,
        points: 1,
      },
      {
        questionId: 10,
        questionText: "Which asset class is generally least risky?",
        options: [
          { optionId: 1001, optionText: "Equities", isCorrect: false },
          { optionId: 1002, optionText: "Bonds", isCorrect: true },
          { optionId: 1003, optionText: "Commodities", isCorrect: false },
          { optionId: 1004, optionText: "Cryptocurrency", isCorrect: false },
        ],
        selectedOptionId: 1002,
        correctOptionId: 1002,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 106,
    categoryId: 6,
    topicId: 601,
    difficultyId: 2,
    dateTaken: "2024-10-20",
    totalScore: 2,
    title: {
      category: "Retirement Planning",
      topic: "Basics of Retirement Accounts",
      level: "Easy",
    },
    rank: 2,
    questions: [
      {
        questionId: 11,
        questionText: "What is the primary benefit of a Roth IRA?",
        options: [
          { optionId: 1101, optionText: "Tax-free growth", isCorrect: true },
          { optionId: 1102, optionText: "Lower risk", isCorrect: false },
          {
            optionId: 1103,
            optionText: "Guaranteed returns",
            isCorrect: false,
          },
          { optionId: 1104, optionText: "High liquidity", isCorrect: false },
        ],
        selectedOptionId: 1101,
        correctOptionId: 1101,
        points: 1,
      },
      {
        questionId: 12,
        questionText:
          "At what age can Roth IRA contributions be withdrawn without penalty?",
        options: [
          { optionId: 1201, optionText: "55", isCorrect: false },
          { optionId: 1202, optionText: "59½", isCorrect: true },
          { optionId: 1203, optionText: "62", isCorrect: false },

          { optionId: 1204, optionText: "65", isCorrect: false },
        ],
        selectedOptionId: 1202,
        correctOptionId: 1202,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 107,
    categoryId: 7,
    topicId: 701,
    difficultyId: 1,
    dateTaken: "2024-10-22",
    totalScore: 2,
    title: {
      category: "Retirement Planning",
      topic: "Basics of Retirement Accounts",
      level: "Easy",
    },
    rank: 1,
    questions: [
      {
        questionId: 13,
        questionText: "What is an index fund?",
        options: [
          {
            optionId: 1301,
            optionText: "A fund that tracks a specific index",
            isCorrect: true,
          },
          {
            optionId: 1302,
            optionText: "A fund that invests in stocks only",
            isCorrect: false,
          },
          {
            optionId: 1303,
            optionText: "A type of hedge fund",
            isCorrect: false,
          },
          {
            optionId: 1304,
            optionText: "A fund with high fees",
            isCorrect: false,
          },
        ],
        selectedOptionId: 1301,
        correctOptionId: 1301,
        points: 1,
      },
      {
        questionId: 14,
        questionText:
          "What is the advantage of low expense ratios in index funds?",
        options: [
          { optionId: 1401, optionText: "Higher returns", isCorrect: true },
          { optionId: 1402, optionText: "Lower risk", isCorrect: false },
          { optionId: 1403, optionText: "Less volatility", isCorrect: false },
          {
            optionId: 1404,
            optionText: "Guaranteed profits",
            isCorrect: false,
          },
        ],
        selectedOptionId: 1401,
        correctOptionId: 1401,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
  {
    quizId: 108,
    categoryId: 8,
    topicId: 801,
    difficultyId: 2,
    dateTaken: "2024-10-25",
    totalScore: 2,
    title: {
      category: "Retirement Planning",
      topic: "Basics of Retirement Accounts",
      level: "Easy",
    },
    rank: 2,
    questions: [
      {
        questionId: 15,
        questionText:
          "What is the capital asset pricing model (CAPM) used for?",
        options: [
          {
            optionId: 1501,
            optionText: "Determining stock price",
            isCorrect: false,
          },
          {
            optionId: 1502,
            optionText: "Calculating expected return",
            isCorrect: true,
          },
          { optionId: 1503, optionText: "Valuing bonds", isCorrect: false },
          {
            optionId: 1504,
            optionText: "Predicting market trends",
            isCorrect: false,
          },
        ],
        selectedOptionId: 1502,
        correctOptionId: 1502,
        points: 1,
      },
      {
        questionId: 16,
        questionText: "What does beta measure in finance?",
        options: [
          { optionId: 1601, optionText: "Market risk", isCorrect: true },
          { optionId: 1602, optionText: "Liquidity risk", isCorrect: false },
          { optionId: 1603, optionText: "Credit risk", isCorrect: false },
          { optionId: 1604, optionText: "Operational risk", isCorrect: false },
        ],
        selectedOptionId: 1601,
        correctOptionId: 1601,
        points: 1,
      },
    ],
    retakeAvailable: true,
  },
];

function QuizHistory() {
  const [quizData, setQuizData] = useState(quizDatatemp);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedDifficultyLevels, setSelectedDifficultyLevels] =
    useState(null);
  const [topic, setTopic] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  useEffect(() => {
    let tempCategories = [];
    quizData.categories.map((category) => {
      tempCategories.push({
        value: category.id,
        id: category.id,
        label: category.name,
      });
      return { value: "chocolate", label: "Chocolate" };
    });

    setCategory(tempCategories);
  }, [quizData]);
  useEffect(() => {
    if (selectedCategory) {
      let tempTopics = [];
      quizData.categories.map((category) => {
        if (category.id == selectedCategory?.id) {
          tempTopics.push(
            ...category.topics.map((topic) => ({
              value: topic.topicId,
              id: topic.topicId,
              label: topic.name,
            }))
          );
        }
      });
      let tempDifficultyLevels = quizData.difficultyLevels.map(
        (difficultyLevel) => ({
          value: difficultyLevel.id,
          id: difficultyLevel.id,
          label: difficultyLevel.name,
        })
      );
      setTopic(tempTopics);
      setDifficultyLevels(tempDifficultyLevels);
    }
  }, [JSON.stringify(selectedCategory)]);

  const onChangeHanddler = (type, value) => {
    if (type == "category") {
      setSelectedCategory(value);
      setSelectedTopic(null);
      setSelectedDifficultyLevels(null);
    } else if (type == "topic") {
      setSelectedTopic(value);
      setSelectedDifficultyLevels(null);
    } else {
      setSelectedDifficultyLevels(value);
    }
  };
  const isTakeQuizDisabled = !(
    selectedCategory?.id &&
    selectedDifficultyLevels?.id &&
    selectedTopic?.id
  );
  return (
    <>
      {/* <Feedback /> */}
      <div className="full--container">
        <div className="quick--top--section quiz--his-sm">
          <div className="w-1211">
            <div className="quiz--wrapper">
              <div className="quiz--top-left">
                <img
                  src={didYouKnow}
                  width={315}
                  height={289}
                  alt="did you know"
                />
                <span className="big--text">Take A Quiz Now</span>
                <span className="small--text">
                  Get to test your Knowledge about finance and other text
                  anamalities.{" "}
                </span>
              </div>
              <div className="quiz--top-right">
                <div className="card--dropdown">
                  <div className="card--dropdown-items width-100">
                    <CustomeSelect
                      onChange={(value) => onChangeHanddler("category", value)}
                      value={selectedCategory}
                      optionsSelect={category}
                      placeholder="Select Category"
                      icon={iconDashboard}
                    />
                  </div>
                  <div className="card--dropdown-items width-100">
                    <CustomeSelect
                      onChange={(value) => onChangeHanddler("topic", value)}
                      value={selectedTopic}
                      optionsSelect={topic}
                      placeholder="Select Topic"
                      icon={iconTopic}
                    />
                  </div>
                  <div className="card--dropdown-items width-100">
                    <CustomeSelect
                      onChange={(value) =>
                        onChangeHanddler("difficulty", value)
                      }
                      value={selectedDifficultyLevels}
                      optionsSelect={difficultyLevels}
                      placeholder="Select Difficulty"
                      icon={iconDifficulty}
                    />
                  </div>
                  <button
                    disabled={isTakeQuizDisabled}
                    type="button"
                    onClick={() => {
                      alert("take");
                    }}
                    className="btn--startQuiz width-100"
                  >
                    Start Quiz
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main--section">
          <div className="w-1211">
            <div className="quiz--header">
              <span className="quiz--header-text">Quiz History</span>
              <button type="button" className="btn--seeAll">
                See all
              </button>
            </div>
            <div className="quiz--container row--container">
              {quizHistory.map((singleQuiz) => {
                return (
                  <div key={singleQuiz.quizId} className="col--2 sm--col-1">
                    <QuizHistoryCard
                      category={singleQuiz.title.category}
                      topic={singleQuiz.title.topic}
                      level={singleQuiz.title.level}
                      dateTime={singleQuiz.dateTaken}
                      rankValue={singleQuiz.rank}
                      pointValue={singleQuiz.totalScore}
                      retakeAvailable={singleQuiz.retakeAvailable}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuizHistory;
