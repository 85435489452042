import React from "react";
import "./InputFieldFloatingLabel.css";

function InputFieldFloatingLabel({
  label,
  inputType,
  placeholder,
  maxLength,
  onChangeHandler,
  value,
  name,
}) {
  return (
    <>
      <div className="floating--label">
        <label htmlFor={label}>{label}</label>
        <input
          name={name}
          onChange={(e) => {
            e.preventDefault();
            const { name, value } = e.target;
            onChangeHandler(name, value);
          }}
          value={value}
          type={inputType}
          className="input--field"
          placeholder={placeholder}
          id={label}
          maxLength={maxLength}
        />
      </div>
    </>
  );
}

export default InputFieldFloatingLabel;
