import React from "react";
import BannerMobile from "../BannerMobile/BannerMobile";
import CardImage from "../CardImage/CardImage";
import imageCard1 from "../assets/image/AboutUs/image-1.png";
import imageCard2 from "../assets/image/AboutUs/image-2.png";
import imageCard3 from "../assets/image/AboutUs/image-3.png";
import bannerAbout from "../assets/image/about-banner.png";
import "./AboutUs.css";
import PointerSection from "../PointerSection/PointerSection";
import vistorIcon from "../assets/image/icon-vector.svg";
import photoImg from "../assets/image/photo-img.svg";

function AboutUs() {
  return (
    <>
      <div className="full--container">
        <div className="main--section">
          <div className="contact--top">
            <div className="contact--left">
              <div className="contact--left-inner">
                <PointerSection title="About Us" colorText="text--white" />
                <span className="big--title">Welcome to Finyogi</span>
                <span className="sm--text-cd">
                  Empowering individuals with financial literacy and knowledge
                  to achieve their goals with confidence.
                </span>
              </div>
            </div>
          </div>

          <div className="full-in-container">
            <div className="ab--card">
              <div className="ab--card-left">
                <div className="im--card">
                  <img
                    src={photoImg}
                    className="im-img-mweb"
                    alt="Finyogi Illustration"
                  />
                  <span className="im--card-title">
                    Empowering Financial Literacy for Everyone
                  </span>
                  <span className="im--card-sub">
                    At Finyogi, we believe that understanding finance is the
                    foundation for personal and professional success. Our
                    mission is to simplify financial concepts, making them
                    accessible, engaging, and actionable for people from all
                    walks of life. Whether you're a novice or an experienced
                    investor, our platform provides the tools and knowledge to
                    take charge of your financial future.
                  </span>
                  <span className="line--text-title">
                    Discover How We Can Help You
                  </span>
                </div>
              </div>

              <div className="ab--card-right">
                <PointerSection
                  title="Our Vision & Mission"
                  colorText="text--black"
                />
                <span className="tile--top title-top-w mt-15">
                  Empowering Financial Futures
                </span>
                <div className="cd--dd">
                  <div className="cd-vistor">
                    <img
                      src={vistorIcon}
                      width={20}
                      height={20}
                      alt="Vision icon"
                    />
                    <div className="vi-rigth">
                      <span className="vi--title">Our Vision</span>
                      <span className="vi--info">
                        To create a world where financial knowledge is
                        universally accessible and empowers individuals to
                        achieve personal and professional success.
                      </span>
                    </div>
                  </div>
                  <div className="cd-vistor">
                    <img
                      src={vistorIcon}
                      width={20}
                      height={20}
                      alt="Mission icon"
                    />
                    <div className="vi-rigth">
                      <span className="vi--title">Our Mission</span>
                      <span className="vi--info">
                        To make financial literacy engaging, practical, and
                        accessible through expert-led courses and interactive
                        learning tools.
                      </span>
                    </div>
                  </div>
                  <button type="button" className="btn-m-about">
                    More About Us
                  </button>
                </div>
              </div>
            </div>

            <div className="w-1211">
              <div className="our--talent-section p-t-b-80">
                <div className="section-1">
                  <div className="section-1-left">
                    <span className="tile--top">About Finyogi</span>
                    <span className="tile--top-info">
                      We simplify financial concepts like budgeting, investing,
                      taxes, and retirement planning. Join Finyogi to unlock
                      practical tools for financial empowerment.
                    </span>
                  </div>
                  <div className="section-1-right">
                    <img src={bannerAbout} alt="About Finyogi banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full-in-container bg--color-lg-gray">
            <div className="w-1211">
              <div className="our--talent-section p-t-b-80">
                <div className="block--center">
                  <PointerSection title="Our Team" colorText="text--black" />
                </div>
                <div className="card--name">Meet Our Experts</div>
                <div className="section-1">
                  <div className="section-1-left">
                    <span className="tile--top">About Our CEO</span>
                    <span className="tile--top-info">
                      <span style={{ fontWeight: "bold" }}>
                        CA Yatish Kumar
                      </span>
                      , the founder and CEO of Finyogi, brings a wealth of
                      expertise and a passion for financial empowerment. With a
                      background in leading institutions like{" "}
                      <span style={{ fontWeight: "bold" }}>ICICI Bank</span> and{" "}
                      <span style={{ fontWeight: "bold" }}>Tata Capital</span>,
                      he has firsthand experience in navigating the complexities
                      of the financial world. Recognizing the gap in financial
                      literacy, CA Yatish Kumar founded Finyogi to bridge this
                      divide. His vision is to create a world where financial
                      knowledge is not a privilege but a right, empowering
                      individuals to make informed decisions and secure their
                      financial futures. Under his leadership, Finyogi has
                      become a trusted name in financial education, combining
                      expert-led courses, practical tools, and interactive
                      learning experiences to make financial literacy accessible
                      to all.
                    </span>
                  </div>
                  <div className="section-1-right">
                    <CardImage
                      image={imageCard2}
                      alt="CA Yatish Kumar"
                      name="CA Yatish Kumar"
                      designation="Founder & CEO"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full-in-container">
            <div className="w-1211 m-pd-0">
              <div className="our--talent-section p-t-b-80">
                <div className="block--center">
                  <PointerSection title="Our Family" colorText="text--black" />
                </div>
                <div className="card--name">Our Talents</div>
                <div className="talent--section">
                  <CardImage
                    image={imageCard1}
                    alt="Nattasha Kelvin"
                    name="Nattasha Kelvin"
                    designation="Managing Director"
                  />
                  <CardImage
                    image={imageCard2}
                    alt="David Simpson"
                    name="David Simpson"
                    designation="Designer"
                  />
                  <CardImage
                    image={imageCard3}
                    alt="Madeleine Grant"
                    name="Madeleine Grant"
                    designation="Marketing Specialist"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BannerMobile />
      </div>
    </>
  );
}

export default AboutUs;
