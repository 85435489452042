import { getHeaders } from "../../api/Configs";
import Service from "../../api/Services";
import {
  Forgot_Password_Url,
  Login_Url,
  Logout_Url,
  Reset_Password_Url,
  Signup_Url,
} from "../../utilities/Url";

export const createAccount = (param) => {
  const headers = getHeaders();
  const url = Signup_Url;
  return Service.getInstance.post(url, param, {
    isHandlerEnabled: true,
    headers: headers,
  });
};
export const loginAccount = (param) => {
  const headers = getHeaders();
  const url = Login_Url;
  return Service.getInstance.post(url, param, {
    isHandlerEnabled: true,
    headers: headers,
  });
};
export const logoutAccount = (param) => {
  const headers = getHeaders();
  const url = Logout_Url;
  return Service.getInstance.post(url, param, {
    isHandlerEnabled: true,
    headers: headers,
  });
};
export const forgetPassword = (param) => {
  const headers = getHeaders();
  const url = Forgot_Password_Url;
  return Service.getInstance.post(url, param, {
    isHandlerEnabled: true,
    headers: headers,
  });
};
export const resetPassword = (param) => {
  const headers = getHeaders();
  const url = Reset_Password_Url;
  return Service.getInstance.post(url, param, {
    isHandlerEnabled: true,
    headers: headers,
  });
};
