import React from "react";
import "./Loader.css"; // Import styles for the loader

const Loader = ({ size = "medium", color = "#000", message = "" }) => {
  return (
    <div className={`loader-container ${size}`}>
      <div
        className="spinner"
        style={{
          borderColor: `${color} transparent transparent transparent`,
        }}
      ></div>
      {message && <p className="loader-message">{message}</p>}
    </div>
  );
};

export default Loader;
