import React from "react";
import mobileBanner from "../assets/image/mobile-banner.svg";
import iconCloud from "../assets/image/cloud-icon.svg";
import iconPlaystore from "../assets/image/img-play-store.svg";
import iconAchievement from "../assets/image/achievement-img.svg";
import iconCustomer from "../assets/image/customer-icon.svg";
import iconmWebPlaystore from "../assets/image/play-store-mweb.svg";
import "./BannerMobile.css";

function BannerMobile() {
  return (
    <>
      <div className="banner--card">
        <div className="banner--card-row">
          {/* Left Section */}
          <div className="banner--card-left">
            <div className="banner--content">
              <span className="banner--content-text">
                Take Charge of Your Financial Future
              </span>
              <span className="banner--content-sub-text">
                Finyogi equips you with the tools and knowledge to navigate the
                financial world with confidence. From beginners to seasoned
                investors, we've got you covered with insights, strategies, and
                resources tailored for everyone.
              </span>
              {/* Download App Section */}
              <div className="banner--download-section">
                <div className="play-store-links">
                  <img
                    src={iconPlaystore}
                    alt="Play Store Icon"
                    className="play-store-icon web-play"
                  />
                </div>
              </div>

              {/* Achievements Section */}
              {/* Achievements Section */}
              <div className="banner--customer">
                <div className="customer--items">
                  <img
                    src={iconAchievement}
                    width={44}
                    height={44}
                    alt="Achievement Icon"
                    className="customer-icon"
                  />
                  <span className="customer-text">
                    Over 5 Years of Expertise
                  </span>
                </div>
                <div className="customer--items">
                  <img
                    src={iconCustomer}
                    width={44}
                    height={44}
                    alt="Customer Icon"
                    className="customer-icon"
                  />
                  <span className="customer-text">
                    Thousands of Happy Learners
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="banner--card-right">
            <img
              src={mobileBanner}
              alt="Mobile Banner"
              width={593}
              height={598}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerMobile;
