import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = () => {
  return (
    <Helmet>
      {/* General Meta Tags */}
      <title>FinYogi</title>
      <meta name="description" content="Unlock The Your Finace Potential" />

      {/* Open Graph Meta Tags for Facebook */}
      <meta property="og:title" content="Your Page Title" />
      <meta
        property="og:description"
        content="A brief description of the page"
      />
      <meta
        property="og:image"
        content="https://yourwebsite.com/path/to/image.jpg"
      />
      <meta property="og:url" content="https://yourwebsite.com/your-page-url" />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content="Your Page Title" />
      <meta
        name="twitter:description"
        content="A brief description of the page"
      />
      <meta
        name="twitter:image"
        content="https://yourwebsite.com/path/to/image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://i.postimg.cc/9QPXhxrJ/logofavicon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://i.postimg.cc/9QPXhxrJ/logofavicon.png"
      />
      <link
        rel="icon"
        type="image/svg+xml"
        href="https://i.postimg.cc/9QPXhxrJ/logofavicon.png"
      />
    </Helmet>
  );
};

export default MetaTags;
