import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [userCredential, setUserCredential] = useState(() =>
    JSON.parse(localStorage.getItem("userCredential"))
  );
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return userCredential ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {};

export default ProtectedRoute;
