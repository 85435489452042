import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";

import MetaTags from "./common/metaTags/MetaTags.js";
import { routes } from "./common/protectedRoute/routes.js";
import ProtectedRoute from "./common/protectedRoute/ProtectedRoute.js";
import AuthenticatedLayout from "./common/protectedRoute/AuthenticatedLayout.js";
function App() {
  const [userCredential, setUserCredential] = useState(null);
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userCredential"));
    setUserCredential(data);
    console.log(data);
  }, []);

  return (
    <BrowserRouter>
      <MetaTags />
      <AuthenticatedLayout>
        <Toaster />
        <Routes>
          {routes.map(({ element, path, isProtected }) => {
            return (
              <Route
                key={path}
                path={path}
                element={
                  isProtected ? (
                    <ProtectedRoute>{element}</ProtectedRoute>
                  ) : (
                    element
                  )
                }
              />
            );
          })}
          {/* Catch-All Route */}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </AuthenticatedLayout>
    </BrowserRouter>
  );
  // return (
  //   <>
  //     {/* <LoginPage /> */}
  //     {/* <template> */}
  //     <div className="container--main">
  //       <div className="header--section">
  //         <ProfileHeader />
  //       </div>
  //       <div className="main--section">
  //         <main>
  //           {/* <MyProfile /> */}
  //           {/* <Artical /> */}
  //           {/* <LearnPage /> */}
  //           {/* <QuizHistory /> */}
  //           {/* <ContactUs /> */}
  //           {/* <FutureValueCalculator /> */}
  //           {/* <PresentValueCalculator /> */}
  //           {/* <CagrcCalculator /> */}
  //           {/* <SipCalculator /> */}
  //           {/* <LearnModules /> */}
  //           {/* <QuizTopic /> */}
  //           {/* <QuestionCard /> */}
  //           <ScoresSection />
  //           {/* <RetakeQuiz /> */}
  //           {/* <ArticalBooks /> */}
  //           {/* <HomePage /> */}
  //           {/* <HomePage2 /> */}
  //           {/* <AboutUs /> */}
  //           {/* <TopicPage /> */}
  //         </main>
  //       </div>
  //       <div className="w-1211">
  //         <DownloadApp />
  //       </div>
  //       <div className="footer--section">
  //         <ProfileFooter />
  //       </div>
  //     </div>
  //     {/* </template> */}
  //   </>
  // );
}

export default App;
