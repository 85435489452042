import React, { useEffect, useState } from "react";
import "./AccountCreate.css";
import InputFieldFloatingLabel from "../InputSection/InputFieldFloatingLabel";
import { useNavigate } from "react-router-dom";
import { createAccount } from "./api/authApiCall";
function AccountCreate() {
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Check if user data is saved in localStorage
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail && savedPassword) {
      //   setEmail(savedEmail);
      //   setPassword(savedPassword);
      //   setRememberMe(true);
    }
  }, []);
  const gotoLoginPageHandler = async () => {
    try {
      navigate("/login", {
        state: {
          type: "login",
        },
      });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const onSubmitHandler = async () => {
    try {
      setIsError(false);
      setIsLoader(true);
      const param = { ...state };
      const data = await createAccount(param);
      localStorage.setItem("userCredential", JSON.stringify(data));
      setIsError(false);
      setIsLoader(false);
      navigate("/home", {
        replace: true,
      });
    } catch (error) {
      setIsError(true);
      setIsLoader(false);
      console.log(error, "error");
    }
  };
  const onChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
    console.log(state);
  };
  return (
    <>
      <h1 className="form--title mb-70">Create an account</h1>
      <div className="form--wrapper">
        <InputFieldFloatingLabel
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"name"}
          value={state["name"] || ""}
          label={"Name"}
          inputType={"text"}
          placeholder={"Enter Your Name"}
        />

        <InputFieldFloatingLabel
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"email"}
          value={state["email"] || ""}
          label={"Email"}
          inputType={"email"}
          placeholder={"example@gmail.com"}
        />

        <InputFieldFloatingLabel
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"password"}
          value={state["password"] || ""}
          label={"Password"}
          inputType={"password"}
          placeholder={"Enter Your Password"}
        />

        <InputFieldFloatingLabel
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"phoneNumber"}
          value={state["phoneNumber"] || ""}
          label={"Phone Number"}
          inputType={"phoneNumber"}
          placeholder={"Enter Your phone Number"}
        />
      </div>

      <div className="form--submit">
        <button
          type="button"
          onClick={() => {
            onSubmitHandler();
          }}
          className="btn--submit"
        >
          Sign Up
        </button>
        <div className="sign-up-block">
          <span className="signup--text">Already have an account?</span>
          <button
            onClick={() => {
              gotoLoginPageHandler();
            }}
            type="button"
            className="btn--sign-up text--underline"
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default AccountCreate;
