import React, { useEffect, useState } from "react";
import InputField from "../InputSection/InputField";
import { useNavigate } from "react-router-dom";
import { loginAccount } from "./api/authApiCall";
import Loader from "../common/loader/Loader";
import showToast from "../common/toast/ToastHelper";
function LoginPage() {
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    // Check if user data is saved in localStorage

    const userCredential = localStorage.getItem("userCredential");

    if (userCredential) {
      const { email } = userCredential;
      setState({ ...state, email });
    }
  }, []);
  const onChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
    console.log(state);
  };
  const gotoSignupPageHandler = () => {
    navigate("/createAccount", {
      state: {
        type: "createAccount",
      },
    });
  };
  const gotoForgetPageHandler = () => {
    navigate("/forgetPage", {
      state: {
        type: "forgetPage",
      },
    });
  };
  const onSubmitHandler = async () => {
    try {
      const param = {
        email: state.email,
        password: state.password,
      };
      setIsLoading(true);
      setIsError(false);
      const loginData = await loginAccount(param);
      console.log(loginData, "loginData");
      localStorage.setItem("userCredential", JSON.stringify(loginData));
      navigate("/home", { replace: true });
      setIsLoading(false);
      setIsError(false);
      showToast("success", "Successfully login");
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMsg(error.message);
      showToast("error", error.message);

      console.log(error, "error");
    }
  };

  return (
    <>
      <h1 className="form--title mb-60">Hi, Welcome Back!</h1>
      <div className="form--input mb-20">
        <InputField
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"email"}
          value={state["email"] || ""}
          label={"Email"}
          inputType={"email"}
          placeholder={"example@gmail.com"}
        />
        <InputField
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"password"}
          value={state["password"] || ""}
          label={"Password"}
          inputType={"password"}
          placeholder={"Enter Your Password"}
        />
      </div>
      <div className="remember--section">
        <InputField
          inputType={"checkbox"}
          label={"Remember Me"}
          name={"remember"}
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          value={state["remember"] || false}
        />

        <button
          type="button"
          onClick={() => {
            gotoForgetPageHandler();
          }}
          className="btn--forgot"
        >
          Forgot Password?
        </button>
      </div>
      <div className="form--submit">
        {isLoading && (
          <div style={{ margin: "2.5%" }}>
            <Loader size="small" />
          </div>
        )}
        {isError && errorMsg && (
          <span className="error--text">{errorMsg} </span>
        )}

        <button
          disabled={!state["email"] || !state["password"]}
          onClick={() => {
            onSubmitHandler();
          }}
          type="button"
          className="btn--submit"
        >
          Login
        </button>

        <div
          onClick={() => {
            gotoSignupPageHandler();
          }}
          className="sign-up-block"
        >
          <span className="signup--text">Don`t have an account ? </span>
          <button type="button" className="btn--sign-up">
            Sign Up
          </button>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
