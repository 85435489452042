import toast from "react-hot-toast";

const showToast = (type, message, options = {}) => {
  const config = {
    position: options.position || "top-right",
    autoClose: options.autoClose || 3000,
    hideProgressBar: options.hideProgressBar || false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: options.theme || "light",
    ...options, // Override default options
  };

  switch (type) {
    case "success":
      toast.success(message, config);
      break;
    case "error":
      toast.error(message, config);
      break;
    case "info":
      toast.info(message, config);
      break;
    case "warning":
      toast.warning(message, config);
      break;
    default:
      toast(message, config); // Default is a simple toast
  }
};

export default showToast;
